<template>
  <el-dialog
    v-drag
    title="修改进口商信息"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="content">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item label="法人(中文)" prop="corporationCH">
          <el-input
            v-model="form.corporationCH"
            style="width: 360px"
            placeholder="仅限填写中文字符"
            @input="onInput('corporationCH')"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人(拼音or英文)" prop="corporationEN">
          <el-input
            v-model="form.corporationEN"
            style="width: 360px"
            placeholder="仅限填写空格、英文字符"
            @input="onInput('corporationEN')"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册代理" prop="registeredAgent">
          <el-input
            v-model="form.registeredAgent"
            style="width: 360px"
            placeholder="仅限填写除特殊符号的字符"
            @input="onInput('registeredAgent')"
          ></el-input>
        </el-form-item>
        <el-form-item label="首次注册时间" prop="registerOn">
          <el-date-picker
            v-model="form.registerOn"
            type="date"
            style="width: 360px"
            value-format="yyyy-MM-dd"
            placeholder="选择时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Bond额度" prop="bondLimit">
          <el-input
            v-model="form.bondLimit"
            style="width: 360px"
            placeholder="仅限填写数字"
            @input="onInput('bondLimit')"
          ></el-input>
        </el-form-item>
        <el-form-item label="Bond有效期" prop="bondValidDate">
          <BaseDateCommon
            v-model="form.bondValidDate"
            width="360"
          ></BaseDateCommon>
        </el-form-item>
        <el-form-item label="进口商特性" prop="features">
          <BaseSelectCommon
            v-model="form.features"
            width="320px"
            :type="21"
            :extendTitle="false"
            :multiple="true"
            title="进口商特性"
          ></BaseSelectCommon>
        </el-form-item>
        <el-form-item label="进口商使用有效期" prop="usedValidDate">
          <BaseDateCommon
            v-model="form.usedValidDate"
            width="360"
          ></BaseDateCommon>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="form.remarks"
            style="width: 360px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="isActived" style="width: 360px">
          <el-switch
            v-model="form.isActived"
            :active-text="form.isActived ? '启用' : '停用'">
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers flex_c_c">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确定修改</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseDateCommon = () => import('@/components/base/BaseDateCommon.vue')
const BaseSelectCommon = () => import('@/components/base/BaseSelectCommon.vue')
import {
  updateImporterAdvance
} from '@/api/basic'
export default {
  components: {
    BaseDateCommon,
    BaseSelectCommon
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        corporationCH: '',
        corporationEN: '',
        registeredAgent: '',
        registerOn: '',
        bondLimit: '',
        bondValidDate: '',
        features: '',
        usedValidDate: '',
        remarks: '',
        isActived: true
      },
      rules: {
        // corporationCH: [
        //   { required: true, message: '请输入法人', trigger: 'blur' }
        // ],
        corporationEN: [
          { required: true, message: '请输入法人(拼音or英文)', trigger: 'blur' }
        ],
        registeredAgent: [
          { required: true, message: '请输入注册代理', trigger: 'blur' }
        ],
        registerOn: [
          { required: true, message: '请选择首次注册时间', trigger: 'blur' }
        ],
        bondLimit: [
          { required: true, message: '请输入Bond额度', trigger: 'blur' }
        ],
        bondValidDate: [
          { required: true, message: '请选择Bond有效期', trigger: 'blur' }
        ],
        usedValidDate: [
          { required: true, message: '请选择进口商使用有效期', trigger: 'blur' }
        ],
        features: [
          { required: true, message: '请选择进口商特性', trigger: 'change' }
        ],
        isActived: [
          { required: true }
        ]
      },
      loading: false
    }
  },
  watch: {
    dialogVisible(v) {
      if (v) {
        this.$nextTick(() => {
          this.$refs['form']?.resetFields()
          if (this.editData.id) {
            const bondValidStart = this.editData.bondValidStart
            const bondValidEnd = this.editData.bondValidEnd
            const bondValidDate = bondValidStart && bondValidEnd ? [bondValidStart, bondValidEnd] : ''
            const usedValidStart = this.editData.usedValidStart
            const usedValidEnd = this.editData.usedValidEnd
            const usedValidDate = usedValidStart && usedValidEnd ? [usedValidStart, usedValidEnd] : ''
            this.form = {
              bondValidDate,
              usedValidDate,
              corporationCH: this.editData.corporationCH || '',
              corporationEN: this.editData.corporationEN || '',
              registeredAgent: this.editData.registeredAgent || '',
              registerOn: this.editData.registerOn || '',
              bondLimit: this.editData.bondLimit,
              features: this.editData.features ? this.editData.features.split('\\') : '',
              remarks: this.editData.remarks || '',
              isActived: this.editData.isActived
            }
          }
        })
      }
    }
  },
  methods: {
    onInput(type) {
      type === 'corporationCH' &&
        this.$set(
          this.form,
          'corporationCH',
          this.form.corporationCH.replace(/[^\u4e00-\u9fa5]/g, '')
        )
      type === 'corporationEN' &&
        this.$set(
          this.form,
          'corporationEN',
          this.form.corporationEN.replace(/[^a-zA-Z\s]/g, '')
        )
      type === 'registeredAgent' &&
        this.$set(
          this.form,
          'registeredAgent',
          this.form.registeredAgent.replace(/[^a-zA-Z\d\u4e00-\u9fa5]/g, '')
        )
      type === 'bondLimit' &&
        this.$set(
          this.form,
          'bondLimit',
          this.form.bondLimit.replace(/[^\d\.{0, 1}]/g, '').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        )
    },
    cancel() {
      // this.$nextTick(() => {
      //   this.$refs['form']?.resetFields()
      // })
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
          if (valid) {
            this.loading = true
            try {
              const features = this.form.features.join(`\\`)
              const params = {
                ...this.form,
                features,
                importerId: this.editData.id,
                bondValidStart: this.form.bondValidDate[0],
                bondValidEnd: this.form.bondValidDate[1],
                usedValidStart: this.form.usedValidDate[0],
                usedValidEnd: this.form.usedValidDate[1],
                bondLimit: +this.form.bondLimit
              }
              const res = await updateImporterAdvance(params)
              this.loading = false
              if (res.success) {
                this.$message.success(res.errorMessage)
                this.$emit('ok')
                this.$nextTick(() => {
                  this.$refs[formName]?.resetFields()
                })
              } else {
                this.$message.error(res.errorMessage)
              }
            } catch (error) {
              this.loading = false
              console.error(error)
            }
          } else {
            return false
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 20px);
  margin: 0 auto;
  .el-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #eeeeee;
    color: #999999;
    margin-right: 16px;
  }
  .ok {
    background: #2395f3;
    color: #ffffff;
  }
}
</style>
