<template>
  <el-dialog
    v-drag
    title="下属客户"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="opt">
        <div class="add flex_c_c" @click="add()">
          <svg-icon className="icon" iconClass="add" />新增
        </div>
        <div class="delete flex_c_c" @click="deleteCom()">
          <i class="el-icon-delete"></i>删除
        </div>
      </div>
      <div class="data">
        <BaseTable
          :height="440"
          :tableData="tableData"
          :loading="loading"
          :border="true"
          :isPager="false"
        >
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok1">确 定</div>
    </span>
    <AddBaseSubclientsDialog
      :dialogVisible="dialogVisible1"
      :addressBookId="addressBookId"
      :title="title"
      :filterItem="tableData.columns"
      @cancel="dialogVisible1 = false"
      @ok="ok2"
    ></AddBaseSubclientsDialog>
  </el-dialog>
</template>

  <script>
const BaseTable = () => import('@/components/table/BaseTable')
import AddBaseSubclientsDialog from './AddBaseSubclientsDialog.vue'
import {
  addressBookCompanyList
} from '@/api/basic'
import { mapGetters } from 'vuex'
export default {
  components: { BaseTable, AddBaseSubclientsDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    addressBookId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'companyId',
            label: '客户编码',
            width: 180,
            formatter: (row) => {
              return this.companyDrapdown.filter(a => +a.id === +row.companyId)[0].identityCode
            },
            resizable: true
          },
          {
            filed: 'companyId',
            label: '客户名称',
            formatter: (row) => {
              return this.companyDrapdown.filter(a => +a.id === +row.companyId)[0].label
            },
            resizable: true
          }
        ],
        columns: []
      },
      dialogVisible1: false,
      title: 0
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown'])
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    async getList() {
      this.loading = true
      try {
        const res = await addressBookCompanyList({
          addressBookId: this.addressBookId
        })
        if (res.success) {
          this.tableData.columns = res.data
          this.loading = false
        } else {
          this.$message.error(res.errorMessage)
          this.loading = false
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    ok1() {
      this.cancel()
    },
    ok2() {
      this.getList()
      this.dialogVisible1 = false
    },
    add() {
      this.dialogVisible1 = true
      this.title = 0
    },
    deleteCom() {
      this.dialogVisible1 = true
      this.title = 2
    }
  }
}
</script>

  <style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  .opt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    & div {
    width: 80px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
      svg {
        font-size: 20px;
      }
    }
    .add {
      width: 80px;
      background: #35ba83;
    }
    .delete {
      background: #2395f3;
      margin-left: 10px;
    }
  }
  .data {
    width: 100%;
    height: auto;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      .fp-cell {
        border: 0;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
