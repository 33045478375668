<template>
  <el-dialog
    v-drag
    title="上传资料"
    :visible.sync="dialogVisible"
    width="912px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="opt">
        <el-select
          v-model="type"
          style="margin-right: 8px;"
          size="mini"
          placeholder="选择上传类型">
          <el-option
            v-for="(item, $index) in typeOptions"
            :key="$index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-upload
          action="#"
          :show-file-list="false"
          :http-request="httpRequest"
        >
          <div class="upload">
            <img src="@/assets/images/bill/upload-1.png" alt="" />
            选择文件上传
          </div>
        </el-upload>
      </div>
      <div class="data">
        <BaseTable
          :tableData="tableData"
          height="359"
          :isPager="false"
          :border="true"
        >
          <div slot="opt" slot-scope="row" class="optbtn">
            <div
              class="btn"
              @click="check(row.scope)"
            >
              <i class="el-icon-zoom-in"></i>
              查看
            </div>
            <div class="btn" @click="download(row.scope)">
              <img src="@/assets/images/bill/download.png" alt="" />
              下载
            </div>
          </div>
        </BaseTable>
      </div>
      <div class="footer">*暂时只支持图片和PDF查看</div>
    </div>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import {
  uploadImporterFile,
  getImporterFiles
} from '@/api/basic'
export default {
  components: {
    BaseTable
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      type: '',
      typeOptions: [
        { label: 'EIN', value: 0 },
        { label: '护照', value: 1 },
        { label: 'IRS', value: 2 },
        { label: 'SS4', value: 3 }
      ],
      tableData: {
        fileds: [
          {
            filed: 'fileName',
            label: '文件名',
            width: '215',
            resizable: true
          },
          {
            filed: 'type',
            label: '上传类型',
            width: '98',
            resizable: true,
            formatter: (row) => {
              let str
              switch (row.type) {
                case 0:
                  str = 'EIN'
                  break
                case 1:
                  str = '护照'
                  break
                case 2:
                  str = 'IRS'
                  break
                case 3:
                  str = 'SS4'
                  break
              }
              return str
            }
          },
          {
            filed: 'createdOn',
            label: '上传时间',
            width: '140',
            resizable: true,
            formatter: (row) =>
              row.createdOn.slice(0, row.createdOn.length - 3)
          },
          {
            filed: 'createdName',
            label: '上传人',
            width: '100',
            resizable: true
          },
          {
            filed: 'fileName',
            label: '文件类型',
            width: '80',
            resizable: true,
            formatter: (row) => {
              const suffix = row.fileName.slice(
                row.fileName.lastIndexOf('.') + 1,
                row.fileName.length
              )
              return suffix.toUpperCase()
            }
          },
          {
            filed: 'opt',
            label: '操作',
            width: '198',
            isCustom: true,
            resizable: true
          }
        ],
        columns: []
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    cancel() {
      this.type = ''
      this.$emit('cancel')
    },
    async getList() {
      try {
        const res = await getImporterFiles({ importerId: this.editData.id })
        this.tableData.columns = res
      } catch (error) {
        console.error(error)
      }
    },
    async httpRequest({ file }) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('仅限上传图片和PDF文件')
        return false
      }
      if (this.type === '') {
        this.$message.error('请先选择上传类型')
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      try {
        const params = {
          type: this.type,
          importerId: this.editData.id
        }
        const result = await uploadImporterFile(
          params,
          formData
        )
        if (result.data.success) {
          this.$message.success('上传成功')
          this.getList()
        } else {
          this.$message.error(result.data.errorMessage)
        }
      } catch (error) {
        this.$message.error('上传失败')
      }
    },
    async check(item) {
      const suffix = item.fileName.slice(
        item.fileName.lastIndexOf('.') + 1,
        item.fileName.length
      )
      const { fileGuid } = item
      if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'png') {
        window.open(`/basic/api/Address/importerFile?guid=${fileGuid}&preview=${true}`)
      } else if (suffix === 'pdf') {
        window.open(`/basic/api/Address/importerFile?guid=${fileGuid}&preview=${true}`)
      } else {
        this.$message('暂时只支持图片和PDF查看')
      }
    },
    download({ fileGuid }) {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/basic/api/Address/importerFile?guid=${fileGuid}`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  margin: 0 auto;
  .opt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    .upload {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 32px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      box-sizing: border-box;
      cursor: pointer;
      img {
        margin-right: 4px;
      }
    }
    &:deep(.el-select),
    &:deep(.el-input) {
      input {
        height: 34px;
        line-height: 34px;
      }
    }
  }
  .data {
    border-radius: 6px 6px 6px 6px;
    overflow: hidden;
    .optbtn {
      display: flex;
      justify-content: space-around;
    }
    .btn {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
      img {
        margin-right: 4px;
      }
      i {
        font-size: 18px;
        margin-right: 4px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    color: #CDCDCD;
  }
}
</style>
