<template>
  <el-dialog
    v-drag
    :title="title ? '删除下属客户' : '新增下属客户'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="header flex_c_c">
        <el-input
          v-model="searchVal"
          placeholder="请输入客户编码或客户名称"
          style="width: 320px; margin-right: 10px"
          @keydown.enter.native="search"
        ></el-input>
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
      <div class="data">
        <BaseTable
          :height="440"
          :tableData="tableData"
          :loading="loading"
          :border="true"
          :isPager="false"
          @handleSelectionChange="handleSelectionChange"
        >
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>

    <script>
const BaseTable = () => import('@/components/table/BaseTable')
import { distributeAddressBook } from '@/api/basic'
import { mapGetters } from 'vuex'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    addressBookId: {
      type: [Number, String],
      default: 0
    },
    title: {
      type: Number,
      default: 0
    },
    filterItem: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'identityCode',
            label: '客户编码',
            width: 180,
            resizable: true
          },
          {
            filed: 'label',
            label: '客户名称',
            resizable: true
          }
        ],
        columns: [],
        mutiCheck: true
      },
      dialogVisible1: false,
      selectionItem: [],
      searchVal: ''
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown'])
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        const ids = this.filterItem.map((a) => +a.companyId)
        if (!this.title) {
          this.tableData.columns = this.companyDrapdown.filter((a) => {
            return !ids.includes(+a.id)
          })
        } else {
          this.tableData.columns = this.companyDrapdown.filter((a) => {
            return ids.includes(+a.id)
          })
        }
      }
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async ok() {
      const params = {
        operation: this.title,
        addressBookId: +this.addressBookId,
        companyIds: this.selectionItem.map((a) => +a.id)
      }
      try {
        const res = await distributeAddressBook(params)
        if (res.success) {
          this.$emit('ok')
          this.$message.success(res.errorMessage)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.$message.error('操作失败')
        this.loading = false
        console.error(error)
      }
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    search() {
      const ids = this.filterItem.map((a) => +a.companyId)
      if (!this.searchVal) {
        if (!this.title) {
          this.tableData.columns = this.companyDrapdown.filter((a) => {
            return !ids.includes(+a.id)
          })
        } else {
          this.tableData.columns = this.companyDrapdown.filter((a) => {
            return ids.includes(+a.id)
          })
        }
      } else {
        if (!this.title) {
          this.tableData.columns = this.companyDrapdown.filter((a) => {
            if (
              (a.identityCode
                .toLowerCase()
                .includes(this.searchVal.toLowerCase()) ||
              a.label.toLowerCase().includes(this.searchVal.toLowerCase())) && !ids.includes(+a.id)
            ) {
              return a
            }
          })
        } else {
          this.tableData.columns = this.companyDrapdown.filter((a) => {
            if (
              (a.identityCode
                .toLowerCase()
                .includes(this.searchVal.toLowerCase()) ||
              a.label.toLowerCase().includes(this.searchVal.toLowerCase())) && ids.includes(+a.id)
            ) {
              return a
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  border: 0;
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
.content {
  width: calc(100% - 40px);
  height: auto;
  margin: 0 auto;
  .header {
    margin-bottom: 20px;
  }
  .data {
    width: 100%;
    height: auto;
    /deep/ .fTable .el-table {
      border-radius: 4px 4px 4px 4px;
      overflow: hidden;
      .fp-cell {
        border: 0;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>

