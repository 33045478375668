var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.title ? "删除下属客户" : "新增下属客户",
        visible: _vm.dialogVisible,
        width: "800px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "header flex_c_c" },
          [
            _c("el-input", {
              staticStyle: { width: "320px", "margin-right": "10px" },
              attrs: { placeholder: "请输入客户编码或客户名称" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search.apply(null, arguments)
                },
              },
              model: {
                value: _vm.searchVal,
                callback: function ($$v) {
                  _vm.searchVal = $$v
                },
                expression: "searchVal",
              },
            }),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("搜索")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              attrs: {
                height: 440,
                tableData: _vm.tableData,
                loading: _vm.loading,
                border: true,
                isPager: false,
              },
              on: { handleSelectionChange: _vm.handleSelectionChange },
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }