var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contents" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "tab" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "JKSXX",
                  expression: "'JKSXX'",
                },
              ],
              staticClass: "tabItem active",
            },
            [_vm._v(" 进口商信息 ")]
          ),
        ]),
        _c("div", { staticClass: "optheader" }, [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("el-input", {
                staticClass: "input-search",
                attrs: { placeholder: "请输入公司名称" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchVal,
                  callback: function ($$v) {
                    _vm.searchVal = $$v
                  },
                  expression: "searchVal",
                },
              }),
              _c(
                "div",
                { staticClass: "btn-search", on: { click: _vm.search } },
                [_c("i", { staticClass: "el-icon-search" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "add",
                  expression: "'add'",
                },
              ],
              staticClass: "add",
              on: {
                click: function ($event) {
                  return _vm.add()
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/seting/add.png") },
              }),
              _vm._v(" 新增 "),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                  isPager: true,
                },
                on: {
                  dblclick: _vm.dblclick,
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            {
                              class: [
                                "flex_c_c",
                                "isActived",
                                row.scope.isActived
                                  ? "isActived1"
                                  : "isActived2",
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(row.scope.isActived ? "启用" : "停用")
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "attentionName",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "infobox" },
                            [
                              _c("el-row", [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "label label1" }, [
                                    _vm._v("联系人"),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(row.scope.attentionName)),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "el-row",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label1" },
                                      [_vm._v("电话")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(_vm._s(row.scope.phone)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label1" },
                                      [_vm._v("邮箱")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(_vm._s(row.scope.email)),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label1" },
                                      [_vm._v("公司")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(_vm._s(row.scope.companyName)),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "countryName",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "infobox" },
                            [
                              _c(
                                "el-row",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label1" },
                                      [_vm._v("国家")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(_vm._s(row.scope.countryCode)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label4" },
                                      [_vm._v("省州")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(_vm._s(row.scope.state)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label4" },
                                      [_vm._v("城市")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(_vm._s(row.scope.city)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label4" },
                                      [_vm._v("邮编")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(_vm._s(row.scope.postalCode)),
                                    ]),
                                  ]),
                                ]
                              ),
                              row.scope.addressLine1
                                ? _c("el-row", [
                                    _c("div", { staticClass: "infoItem" }, [
                                      _c(
                                        "span",
                                        { staticClass: "label label1" },
                                        [_vm._v("地址一")]
                                      ),
                                      _c("span", { staticClass: "num" }, [
                                        _vm._v(_vm._s(row.scope.addressLine1)),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                              row.scope.addressLine2
                                ? _c("el-row", [
                                    _c("div", { staticClass: "infoItem" }, [
                                      _c(
                                        "span",
                                        { staticClass: "label label1" },
                                        [_vm._v("地址二")]
                                      ),
                                      _c("span", { staticClass: "num" }, [
                                        _vm._v(_vm._s(row.scope.addressLine2)),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "corporationCH",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "infobox" },
                            [
                              _c("el-row", [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "label label2" }, [
                                    _vm._v("中文"),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(row.scope.corporationCH)),
                                  ]),
                                ]),
                              ]),
                              _c("el-row", [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "label label2" }, [
                                    _vm._v("拼音/英文"),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(row.scope.corporationEN)),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "mblStatistics",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "infobox" },
                            [
                              _c("el-row", [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "label label3" }, [
                                    _vm._v("提单量"),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(
                                      _vm._s(row.scope.mblStatistics?.mblCount)
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "el-row",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label3" },
                                      [_vm._v("完结")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(
                                        _vm._s(
                                          row.scope.mblStatistics?.finishedCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label3" },
                                      [_vm._v("查验中")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(
                                        _vm._s(
                                          row.scope.mblStatistics?.holdingCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label3" },
                                      [_vm._v("在途")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(
                                        _vm._s(
                                          row.scope.mblStatistics?.transitCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "infoItem" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label3" },
                                      [_vm._v("已查验")]
                                    ),
                                    _c("span", { staticClass: "num" }, [
                                      _vm._v(
                                        _vm._s(
                                          row.scope.mblStatistics?.holdCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "bondLimit",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "infobox" },
                            [
                              _c("el-row", [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "label label2" }, [
                                    _vm._v("总额度"),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(row.scope.bondLimit)),
                                  ]),
                                ]),
                              ]),
                              _c("el-row", [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "label label2" }, [
                                    _vm._v("已使用"),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(row.scope.bondUsed)),
                                  ]),
                                ]),
                              ]),
                              _c("el-row", [
                                _c("div", { staticClass: "infoItem" }, [
                                  _c("span", { staticClass: "label label2" }, [
                                    _vm._v("未使用"),
                                  ]),
                                  _c("span", { staticClass: "num" }, [
                                    _vm._v(
                                      _vm._s(
                                        row.scope.bondLimit - row.scope.bondUsed
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "usedValidStart",
                      fn: function (row) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatterDate")(
                                  row.scope.usedValidStart
                                )
                              )
                            ),
                          ]),
                          row.scope.usedValidStart && row.scope.usedValidEnd
                            ? _c("div", [_vm._v("~")])
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatterDate")(row.scope.usedValidEnd)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "bondValidStart",
                      fn: function (row) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatterDate")(
                                  row.scope.bondValidStart
                                )
                              )
                            ),
                          ]),
                          row.scope.bondValidStart && row.scope.bondValidEnd
                            ? _c("div", [_vm._v("~")])
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatterDate")(row.scope.bondValidEnd)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "classification",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "address-tag",
                              class: _vm.addressTag(row.scope),
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatAddress")(
                                      row.scope.classification
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "features",
                      fn: function (row) {
                        return [
                          row.scope.features
                            ? _vm._l(
                                row.scope.features.split("\\"),
                                function (item, $index) {
                                  return _c("div", { key: $index }, [
                                    _vm._v(" " + _vm._s(item) + " "),
                                  ])
                                }
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "securityLevel",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            {
                              class: [
                                "level",
                                row.scope.securityLevel
                                  ? "level_red"
                                  : "level_green",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatSecurityLevel")(
                                      row.scope.securityLevel
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom-end",
                                    width: "260",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "level_tabel" }, [
                                    _c(
                                      "div",
                                      { staticClass: "level_tabel-header" },
                                      [
                                        _c("span", [_vm._v("类型")]),
                                        _c("span", [_vm._v("提单数量")]),
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      { staticClass: "level_tabel-body" },
                                      [
                                        _c("li", [
                                          _c("span", [_vm._v("放行")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(row.scope.releaseQty)
                                            ),
                                          ]),
                                        ]),
                                        _c("li", [
                                          _c("span", [_vm._v("扣件")]),
                                          _c("span", [
                                            _vm._v(_vm._s(row.scope.holdQty)),
                                          ]),
                                        ]),
                                        _c("li", [
                                          _c("span", [_vm._v("销毁")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(row.scope.destroyQty)
                                            ),
                                          ]),
                                        ]),
                                        _c("li", [
                                          _c("span", [_vm._v("没收")]),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(row.scope.confiscateQty)
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  row.scope.securityLevel
                                    ? _c("img", {
                                        attrs: {
                                          slot: "reference",
                                          src: require("@/assets/images/system/level.png"),
                                        },
                                        slot: "reference",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "optdiv" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "uploadData",
                                      expression: "'uploadData'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.uploadData(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("上传资料")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "optdiv" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "subclients",
                                      expression: "'subclients'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.subclients(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("下属客户")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "optdiv" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "updateBasicInfo",
                                      expression: "'updateBasicInfo'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.update(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改基础信息")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "optdiv" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "updateImporterInfo",
                                      expression: "'updateImporterInfo'",
                                    },
                                  ],
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateImporter(row.scope)
                                    },
                                  },
                                },
                                [_vm._v("修改进口商信息")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4253988706
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("WarehouseDialog", {
        attrs: { dialogVisible: _vm.dialogVisible, editData: _vm.editData },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
      _c("WarehouseAddDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          editData: _vm.editData1,
          activeName: _vm.activeName,
        },
        on: { ok: _vm.ok1, cancel: _vm.cancel1 },
      }),
      _c("BaseSubclientsDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          addressBookId: _vm.addressBookId,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
        },
      }),
      _c("UpdateImporterInfoDialog", {
        attrs: { dialogVisible: _vm.dialogVisible3, editData: _vm.editData1 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible3 = false
          },
          ok: _vm.ok2,
        },
      }),
      _c("UploadDataDialog", {
        attrs: { dialogVisible: _vm.dialogVisible4, editData: _vm.editData1 },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible4 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }