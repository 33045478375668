<template>
  <div class="contents">
    <div class="header">
      <div class="tab">
        <span
          v-permissson="'JKSXX'"
          class="tabItem active"
        >
          进口商信息
        </span>
      </div>
      <div class="optheader">
        <div class="search">
          <el-input
            v-model="searchVal"
            placeholder="请输入公司名称"
            class="input-search"
            @keydown.enter.native="search"
          ></el-input>
          <div class="btn-search" @click="search">
            <i class="el-icon-search"></i>
          </div>
        </div>
        <div
          v-permissson="'add'"
          class="add"
          @click="add()">
          <img src="@/assets/images/seting/add.png" /> 新增
        </div>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        :isPager="true"
        @dblclick="dblclick"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="isActived" slot-scope="row">
          <div :class="['flex_c_c', 'isActived', row.scope.isActived ? 'isActived1' : 'isActived2']">{{ row.scope.isActived ? '启用' : '停用' }}</div>
        </template>
        <template slot="attentionName" slot-scope="row">
          <div class="infobox">
            <el-row>
              <div class="infoItem">
                <span class="label label1">联系人</span>
                <span class="num">{{ row.scope.attentionName }}</span>
              </div>
            </el-row>
            <el-row style="display: flex">
              <div class="infoItem">
                <span class="label label1">电话</span>
                <span class="num">{{ row.scope.phone }}</span>
              </div>
              <div class="infoItem">
                <span class="label label1">邮箱</span>
                <span class="num">{{ row.scope.email }}</span>
              </div>
            </el-row>
            <el-row style="display: flex">
              <div class="infoItem">
                <span class="label label1">公司</span>
                <span class="num">{{ row.scope.companyName }}</span>
              </div>
              <!-- <div v-if="activeName === '4'" class="infoItem">
                <span class="label">EIN</span>
                <span class="num">{{ row.scope.EIN }}</span>
              </div> -->
            </el-row>
          </div>
        </template>
        <template slot="countryName" slot-scope="row">
          <div class="infobox">
            <el-row style="display: flex">
              <div class="infoItem">
                <span class="label label1">国家</span>
                <span class="num">{{ row.scope.countryCode }}</span>
              </div>
              <div class="infoItem">
                <span class="label label4">省州</span>
                <span class="num">{{ row.scope.state }}</span>
              </div>
              <div class="infoItem">
                <span class="label label4">城市</span>
                <span class="num">{{ row.scope.city }}</span>
              </div>
              <div class="infoItem">
                <span class="label label4">邮编</span>
                <span class="num">{{ row.scope.postalCode }}</span>
              </div>
            </el-row>
            <el-row v-if="row.scope.addressLine1">
              <div class="infoItem">
                <span class="label label1">地址一</span>
                <span class="num">{{ row.scope.addressLine1 }}</span>
              </div>
            </el-row>
            <el-row v-if="row.scope.addressLine2">
              <div class="infoItem">
                <span class="label label1">地址二</span>
                <span class="num">{{ row.scope.addressLine2 }}</span>
              </div>
            </el-row>
          </div>
        </template>
        <template slot="corporationCH" slot-scope="row">
          <div class="infobox">
            <el-row>
              <div class="infoItem">
                <span class="label label2">中文</span>
                <span class="num">{{ row.scope.corporationCH }}</span>
              </div>
            </el-row>
            <el-row>
              <div class="infoItem">
                <span class="label label2">拼音/英文</span>
                <span class="num">{{ row.scope.corporationEN }}</span>
              </div>
            </el-row>
          </div>
        </template>
        <template slot="mblStatistics" slot-scope="row">
          <div class="infobox">
            <el-row>
              <div class="infoItem">
                <span class="label label3">提单量</span>
                <span class="num">{{ row.scope.mblStatistics?.mblCount }}</span>
              </div>
            </el-row>
            <el-row style="display: flex">
              <div class="infoItem">
                <span class="label label3">完结</span>
                <span class="num">{{ row.scope.mblStatistics?.finishedCount }}</span>
              </div>
              <div class="infoItem">
                <span class="label label3">查验中</span>
                <span class="num">{{ row.scope.mblStatistics?.holdingCount }}</span>
              </div>
            </el-row>
            <el-row style="display: flex">
              <div class="infoItem">
                <span class="label label3">在途</span>
                <span class="num">{{ row.scope.mblStatistics?.transitCount }}</span>
              </div>
              <div class="infoItem">
                <span class="label label3">已查验</span>
                <span class="num">{{ row.scope.mblStatistics?.holdCount }}</span>
              </div>
            </el-row>
          </div>
        </template>
        <template slot="bondLimit" slot-scope="row">
          <div class="infobox">
            <el-row>
              <div class="infoItem">
                <span class="label label2">总额度</span>
                <span class="num">{{ row.scope.bondLimit }}</span>
              </div>
            </el-row>
            <el-row>
              <div class="infoItem">
                <span class="label label2">已使用</span>
                <span class="num">{{ row.scope.bondUsed }}</span>
              </div>
            </el-row>
            <el-row>
              <div class="infoItem">
                <span class="label label2">未使用</span>
                <span class="num">{{ row.scope.bondLimit - row.scope.bondUsed }}</span>
              </div>
            </el-row>
          </div>
        </template>
        <template slot="usedValidStart" slot-scope="row">
          <div>{{ row.scope.usedValidStart | formatterDate }}</div>
          <div v-if="row.scope.usedValidStart && row.scope.usedValidEnd">~</div>
          <div>{{ row.scope.usedValidEnd | formatterDate }}</div>
        </template>
        <template slot="bondValidStart" slot-scope="row">
          <div>{{ row.scope.bondValidStart | formatterDate }}</div>
          <div v-if="row.scope.bondValidStart && row.scope.bondValidEnd">~</div>
          <div>{{ row.scope.bondValidEnd | formatterDate }}</div>
        </template>
        <template slot="classification" slot-scope="row">
          <span class="address-tag" :class="addressTag(row.scope)">
            {{ row.scope.classification | formatAddress }}
          </span>
        </template>
        <template slot="features" slot-scope="row">
          <template v-if="row.scope.features">
            <div v-for="(item, $index) in row.scope.features.split('\\')" :key="$index">
              {{ item }}
            </div>
          </template>
        </template>
        <template slot="securityLevel" slot-scope="row">
          <div :class="['level', row.scope.securityLevel ? 'level_red' : 'level_green']">
            {{ row.scope.securityLevel | formatSecurityLevel }}
            <el-popover
              placement="bottom-end"
              width="260"
              trigger="hover">
              <div class="level_tabel">
                <div class="level_tabel-header">
                  <span>类型</span>
                  <span>提单数量</span>
                </div>
                <ul class="level_tabel-body">
                  <li>
                    <span>放行</span>
                    <span>{{ row.scope.releaseQty }}</span>
                  </li>
                  <li>
                    <span>扣件</span>
                    <span>{{ row.scope.holdQty }}</span>
                  </li>
                  <li>
                    <span>销毁</span>
                    <span>{{ row.scope.destroyQty }}</span>
                  </li>
                  <li>
                    <span>没收</span>
                    <span>{{ row.scope.confiscateQty }}</span>
                  </li>
                </ul>
              </div>
              <img v-if="row.scope.securityLevel" slot="reference" src="@/assets/images/system/level.png">
            </el-popover>
          </div>
        </template>
        <!-- <template slot="isDefault" slot-scope="row">
          <div>
            <el-switch
              v-model="row.scope.isDefault"
              active-color="#2395F3"
              inactive-color="#DBE4F2"
              @change="handleDefault(row.scope)"
            >
            </el-switch>
            {{ row.scope.isDefault ? "是" : "否" }}
          </div>
        </template> -->
        <template slot="opt" slot-scope="row">
          <!-- <div class="optbox">
            <img
              v-if="activeName === '0'"
              style="margin-right: 15px; cursor: pointer"
              src="@/assets/images/seting/addresschek.png"
              title="地址校验"
              @click="addresscheck(row.scope)"
            />
            <el-button
              v-permissson="'delete'"
              type="text"
              @click="delet(row.scope)"
            >删除</el-button>
          </div> -->
          <div class="optdiv">
            <el-button
              v-permissson="'uploadData'"
              type="text"
              @click="uploadData(row.scope)"
            >上传资料</el-button
            >
          </div>
          <div class="optdiv">
            <el-button
              v-permissson="'subclients'"
              type="text"
              @click="subclients(row.scope)"
            >下属客户</el-button
            >
          </div>
          <div class="optdiv">
            <el-button
              v-permissson="'updateBasicInfo'"
              type="text"
              @click="update(row.scope)"
            >修改基础信息</el-button>
          </div>
          <div class="optdiv">
            <el-button
              v-permissson="'updateImporterInfo'"
              type="text"
              @click="updateImporter(row.scope)"
            >修改进口商信息</el-button>
          </div>
        </template>
      </BaseTable>
    </div>
    <WarehouseDialog
      :dialogVisible="dialogVisible"
      :editData="editData"
      @ok="ok"
      @cancel="cancel"
    ></WarehouseDialog>
    <WarehouseAddDialog
      :dialogVisible="dialogVisible1"
      :editData="editData1"
      :activeName="activeName"
      @ok="ok1"
      @cancel="cancel1"
    ></WarehouseAddDialog>
    <BaseSubclientsDialog
      :dialogVisible="dialogVisible2"
      :addressBookId="addressBookId"
      @cancel="dialogVisible2 = false"
    >
    </BaseSubclientsDialog>
    <UpdateImporterInfoDialog
      :dialogVisible="dialogVisible3"
      :editData="editData1"
      @cancel="dialogVisible3 = false"
      @ok="ok2"
    >
    </UpdateImporterInfoDialog>
    <UploadDataDialog
      :dialogVisible="dialogVisible4"
      :editData="editData1"
      @cancel="dialogVisible4 = false"
    >
    </UploadDataDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const WarehouseDialog = () => import('@/components/dialog/WarehouseDialog.vue')
const WarehouseAddDialog = () =>
  import('@/components/dialog/WarehouseAddDialog.vue')
import BaseSubclientsDialog from './components/BaseSubclientsDialog.vue'
import UpdateImporterInfoDialog from './components/UpdateImporterInfoDialog.vue'
import UploadDataDialog from './components/UploadDataDialog.vue'
// import config from '@/utils/config'
import { permissions } from '@/utils/common'
import {
  getImporterPages,
  defaultAddressBook,
  deleteAddressBook
} from '@/api/basic'
import {
  GetImporterStatistics
} from '@/api/bill'
import { myMixins } from '@/mixins'
export default {
  name: 'BaseData',
  components: {
    BaseTable,
    WarehouseDialog,
    WarehouseAddDialog,
    BaseSubclientsDialog,
    UpdateImporterInfoDialog,
    UploadDataDialog
  },
  filters: {
    formatAddress(val) {
      const obj = {
        0: '商业',
        1: '混合',
        2: '住宅',
        3: '未知'
      }
      return obj[val]
    },
    formatterDate(v) {
      return v ? v.slice(0, v.length - 9) : ''
    },
    formatSecurityLevel(v) {
      let str
      switch (v) {
        case 0:
          str = '安全'
          break
        case 1:
          str = '危险'
          break
        case 2:
          str = '非常危险'
          break
      }
      return str
    }
  },
  mixins: [myMixins],
  data() {
    return {
      activeName: '20',
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '公司',
            width: '200',
            align: 'center',
            fixed: 'left'
          },
          {
            filed: 'createdOnAdvance',
            label: '登记时间',
            width: '150',
            formatter: (row) =>
              row.createdOnAdvance ? row.createdOnAdvance.slice(0, row.createdOnAdvance.length - 3) : ''
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '100',
            isCustom: true
          },
          {
            filed: 'ein',
            label: 'EIN进口商号码',
            width: '130'
          },
          {
            filed: 'exterEIN',
            label: '对外显示EIN',
            width: '130'
          },
          {
            filed: 'attentionName',
            label: '联系信息',
            width: '380',
            isCustom: true
          },
          {
            filed: 'countryName',
            label: '地址信息',
            width: '460',
            isCustom: true
          },
          {
            filed: 'corporationCH',
            label: '法人',
            width: '200',
            isCustom: true
          },
          {
            filed: 'registeredAgent',
            label: '注册代理',
            width: '100'
          },
          {
            filed: 'registerOn',
            label: '首次注册时间',
            width: '100',
            formatter: (row) =>
              row.registerOn ? row.registerOn.slice(0, row.registerOn.length - 9) : ''
          },
          {
            filed: 'usedValidStart',
            label: '进口商使用有效期',
            width: '120',
            isCustom: true
          },
          {
            filed: 'mblStatistics',
            label: '提单统计',
            width: '220',
            isCustom: true
          },
          {
            filed: 'holdRate',
            label: '查验率统计',
            width: '80',
            formatter: (row) =>
              row.holdRate ? `${row.holdRate}%` : ''
          },
          {
            filed: 'bondLimit',
            label: 'BOND额度',
            width: '160',
            isCustom: true
          },
          {
            filed: 'remarks',
            label: '备注',
            width: '160'
          },
          {
            filed: 'companyId',
            label: '所属公司',
            width: '100',
            formatter: (row) =>
              row.companyId && row.companyId !== '0' ? '外部' : '内部'
          },
          {
            filed: 'bondValidStart',
            label: '美国买磅有效期',
            width: '120',
            isCustom: true
          },
          {
            filed: 'securityLevel',
            label: '安全等级',
            width: '120',
            isCustom: true
          },
          {
            filed: 'features',
            label: '特性',
            width: '100',
            isCustom: true
            // formatter: (row) => {
            //   const features = row.features ? row.features.split('\\') : ''
            //   let str = ''
            //   features && features.forEach(a => {
            //     str += `${a}\n`
            //   })
            //   return str
            // }
          },
          // {
          //   filed: 'isDefault',
          //   label: '是否默认',
          //   width: '',
          //   isCustom: true
          // },
          {
            filed: 'opt',
            label: '操作',
            width: '180',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      loading: false,
      height: 0,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      dialogVisible: false,
      editData: {},
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      editData1: {},
      addressBookId: 0,
      title: '新增-私仓',
      searchVal: ''
    }
  },
  computed: {
    // fileds() {
    //   const fileds = this.tableData.fileds
    //   if (this.activeName === '1') {
    //     return fileds
    //   } else {
    //     const arr = [
    //       {
    //         filed: 'isDefault',
    //         label: '是否默认',
    //         width: '',
    //         isCustom: true
    //       },
    //       {
    //         filed: 'opt',
    //         label: '操作',
    //         width: '140',
    //         isCustom: true,
    //         fixed: 'right'
    //       }
    //     ]
    //     return [...fileds, ...arr]
    //   }
    // }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = ['TTXX']
      this.active = codeLits.indexOf(menu.children[0]?.code)
      this.getList(this.pager)
    }
  },
  methods: {
    addressTag(row) {
      let className = ''
      switch (row.classification) {
        // 0 = BUSINESS(商业), 1 = MIXED(混合), 2 = RESIDENTIAL(住宅), 3 = UNKNOWN(未知)
        case 0:
          className = 'address-tag-1'
          break
        case 1:
          className = ''
          break
        case 2:
          className = 'address-tag-0'
          break
        case 3:
          className = 'address-tag-2'
          break
      }
      return className
    },
    dblclick() {
      console.log('双击')
    },
    handleClick() {
      this.initList()
    },
    handlePageChange(pager) {
      this.pager = pager
      this.getList(pager)
    },
    handleSelectionChange() {
      console.log('选择框')
    },
    sortChange(params) {
      console.log('排序', params) // undefined 不排序 1： 升序 2： 降序
    },
    async getList(params) {
      params = {
        ...params,
        queryParam: {
          type: this.activeName,
          search: this.searchVal || undefined
          // supplierId: config.supplyCode
        }
      }
      this.loading = true
      try {
        const res = await getImporterPages(params)
        if (res.success) {
          this.tableData.total = res.data.total
          this.loading = false
          const companyNames = res.data.list.map(a => a.companyName)
          if (companyNames.length > 0) {
           GetImporterStatistics(companyNames).then(result => {
            if (result.success) {
              this.tableData.columns = res.data.list.map((a, i) => {
                const mblStatistics = {
                  mblCount: result.data[i]?.mblCount,
                  finishedCount: result.data[i]?.finishedCount,
                  holdingCount: result.data[i]?.holdingCount,
                  transitCount: result.data[i]?.transitCount,
                  holdCount: result.data[i]?.holdCount
                }
                const holdRate = result.data[i]?.holdRate.toString()
                const bondUsed = result.data[i]?.bondUsed
                const securityLevel = result.data[i]?.securityLevel
                const releaseQty = result.data[i]?.releaseQty // 放行
                const holdQty = result.data[i]?.holdQty // 扣件
                const destroyQty = result.data[i]?.destroyQty // 销毁
                const confiscateQty = result.data[i]?.confiscateQty // 没收
                return {
                  ...a,
                  mblStatistics,
                  holdRate,
                  bondUsed,
                  securityLevel,
                  releaseQty,
                  holdQty,
                  destroyQty,
                  confiscateQty
                }
              })
              this.loading = false
            } else {
              this.loading = false
              this.$message.error(result.errorMessage)
            }
          })
          } else {
              this.tableData.columns = []
              this.loading = false
          }
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.getList(param)
    },
    update(row) {
      this.editData1 = row
      this.dialogVisible1 = true
    },
    updateImporter(row) {
      this.editData1 = row
      this.dialogVisible3 = true
    },
    uploadData(row) {
      this.editData1 = row
      this.dialogVisible4 = true
    },
    delet(row) {
      const params = {
        id: row.id
      }
      deleteAddressBook(params).then((res) => {
        if (res.success) {
          this.$message.success(res.errorMessage)
          this.getList()
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    addresscheck(row) {
      console.log(row)
      this.editData = row
      this.dialogVisible = true
    },
    add() {
      this.dialogVisible1 = true
    },
    ok() {
      this.dialogVisible = false
      this.editData = {}
    },
    cancel() {
      this.dialogVisible = false
      this.editData = {}
    },
    ok1() {
      this.initList()
      this.dialogVisible1 = false
      this.editData1 = {}
    },
    ok2() {
      this.dialogVisible3 = false
      this.editData1 = {}
      this.getList()
    },
    cancel1() {
      this.dialogVisible1 = false
      this.editData1 = {}
    },
    async handleDefault(row) {
      try {
        const result = await defaultAddressBook({
          id: row.id,
          isDefault: row.isDefault
        })
        if (result.success) {
          this.$message.success(result.errorMessage)
          this.getList()
        } else {
          this.tableData.columns.splice(
            this.tableData.columns.indexOf(row),
            1,
            {
              ...row,
              isDefault: !row.isDefault
            }
          )
          this.$message.error(result.errorMessage)
        }
      } catch (erroe) {
        console.error(erroe)
      }
    },
    subclients(row) {
      this.addressBookId = row.id
      this.dialogVisible2 = true
    },
    search() {
      this.getList(this.pager)
    }
  }
}
</script>

<style lang="less" scoped>
.contents {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 15px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
  }
}
.data {
  width: 100%;
  height: calc(100% - 50px);
  z-index: 1;
  & .fTable {
    border-top: 1px solid #ebeef5;
    box-sizing: border-box;
  }
  & .infobox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: auto;
    & .el-row + .el-row {
      margin-top: 4px;
    }
  }
  & .infoItem {
    display: flex;
    .label {
      display: flex;
      height: 24px;
      background: rgba(35, 149, 243, 0.15);
      border-radius: 2px 2px 2px 2px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      box-sizing: border-box;
    }
    .label1 {
      align-items: center;
      width: 50px;
      padding: 0 7px;
    }
    .label2 {
      justify-content: center;
      align-items: center;
      width: 60px;
    }
    .label3 {
      justify-content: center;
      align-items: center;
      width: 48px;
    }
    .label4 {
      justify-content: center;
      align-items: center;
      width: 40px;
    }
    .num {
      display: flex;
      align-items: center;
      height: 24px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #77879e;
      margin-left: 4px;
    }
    & + .infoItem {
      margin-left: 20px;
    }
  }
  & .isActived {
    width: 40px;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    margin: 0 auto;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
  & .isActived1 {
    background: rgba(53, 186, 131, .1);
    color: #35BA83;
  }
  & .isActived2 {
    background: rgba(254, 69, 61, .1);
    color: #FE453D;
  }
  & .level {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      cursor: pointer;
    }
  }
  & .level_green {
    color: #35BA83;
  }
  & .level_red {
    color: #FE453D;
  }
  &:deep(.el-popover__reference-wrapper) {
    display: flex;
    align-items: center;
  }
  .optdiv {
    .el-button {
      padding: 2.5px 0;
    }
  }
}
.optheader {
  display: flex;
  .search {
    position: relative;
    display: flex;
    width: 200px;
    margin-right: 8px;
    .input-search {
      width: 166px;
      height: 30px !important;
      /deep/ .el-input__inner {
        height: 30px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .btn-search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border: 1px solid #dbe4f2;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  .add {
    width: 84px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    line-height: 24px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .filter {
    width: 84px;
    height: 32px;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2395f3;
    border-color: rgba(35, 149, 243, 0.4);
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    cursor: pointer;
    line-height: 24px;
  }
}
.opbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  .NObtn {
    width: 84px;
    height: 32px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #feaa4f;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #feaa4f;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }
  .Yesbtn {
    width: 84px;
    height: 32px;
    background: #35ba83;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
    margin-right: 16px;
    cursor: pointer;
  }
}
.opt {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.address-tag {
  width: auto;
  height: 24px;
  display: inline-block;
  border-radius: 4px 4px 4px 4px;
  padding: 0 8px;
  box-sizing: border-box;
  & + .address-tag {
    margin-top: 4px;
  }
}
.address-detail {
  width: auto;
  height: 48px;
  font-size: 14px;
  font-weight: Regular;
  color: #25396f;
  line-height: 24px;
  text-indent: 4px;
  text-align: left;
}
.address-tag-0 {
  background: rgba(53, 186, 131, 0.1);
  color: rgba(53, 186, 131, 1);
}
.address-tag-1 {
  background: rgba(35, 149, 243, 0.1);
  color: rgba(35, 149, 243, 1);
}
.address-tag-3 {
  background: rgba(157, 103, 254, 0.1);
  color: rgba(157, 103, 254, 1);
}
.address-tag-2 {
  background: rgba(254, 69, 61, 0.1);
  color: rgba(254, 69, 61, 1);
}
.tag {
  width: 100%;
  height: 64px;
}
/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
  margin: 0;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-top: none;
}
/deep/ .el-tabs__item {
  height: 24px;
  margin-top: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  line-height: normal;
  color: #7c8db5;
}
/deep/ .el-tabs__item.is-active {
  color: #409eff !important;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav:first-child {
  border-left: none;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav:last-child {
  border-right: none;
}
</style>
<style lang="less">
.level_tabel {
  width: 100%;
  height: 200px;
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  .level_tabel-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #F7F7F7;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      & + span {
        border-left: 1px solid #EEEEEE;
        box-sizing: border-box;
      }
    }
  }
  .level_tabel-body {
    width: 100%;
    height: calc(100% - 40px);
    & li {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #EEEEEE;
      box-sizing: border-box;
      & span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        & + span {
          border-left: 1px solid #EEEEEE;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
