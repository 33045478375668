var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "修改进口商信息",
        visible: _vm.dialogVisible,
        width: "800px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "法人(中文)", prop: "corporationCH" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "360px" },
                    attrs: { placeholder: "仅限填写中文字符" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("corporationCH")
                      },
                    },
                    model: {
                      value: _vm.form.corporationCH,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "corporationCH", $$v)
                      },
                      expression: "form.corporationCH",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法人(拼音or英文)", prop: "corporationEN" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "360px" },
                    attrs: { placeholder: "仅限填写空格、英文字符" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("corporationEN")
                      },
                    },
                    model: {
                      value: _vm.form.corporationEN,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "corporationEN", $$v)
                      },
                      expression: "form.corporationEN",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册代理", prop: "registeredAgent" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "360px" },
                    attrs: { placeholder: "仅限填写除特殊符号的字符" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("registeredAgent")
                      },
                    },
                    model: {
                      value: _vm.form.registeredAgent,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "registeredAgent", $$v)
                      },
                      expression: "form.registeredAgent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "首次注册时间", prop: "registerOn" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "360px" },
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.form.registerOn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "registerOn", $$v)
                      },
                      expression: "form.registerOn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Bond额度", prop: "bondLimit" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "360px" },
                    attrs: { placeholder: "仅限填写数字" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("bondLimit")
                      },
                    },
                    model: {
                      value: _vm.form.bondLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bondLimit", $$v)
                      },
                      expression: "form.bondLimit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Bond有效期", prop: "bondValidDate" } },
                [
                  _c("BaseDateCommon", {
                    attrs: { width: "360" },
                    model: {
                      value: _vm.form.bondValidDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bondValidDate", $$v)
                      },
                      expression: "form.bondValidDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "进口商特性", prop: "features" } },
                [
                  _c("BaseSelectCommon", {
                    attrs: {
                      width: "320px",
                      type: 21,
                      extendTitle: false,
                      multiple: true,
                      title: "进口商特性",
                    },
                    model: {
                      value: _vm.form.features,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "features", $$v)
                      },
                      expression: "form.features",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "进口商使用有效期", prop: "usedValidDate" } },
                [
                  _c("BaseDateCommon", {
                    attrs: { width: "360" },
                    model: {
                      value: _vm.form.usedValidDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "usedValidDate", $$v)
                      },
                      expression: "form.usedValidDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "360px" },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "360px" },
                  attrs: { label: "状态", prop: "isActived" },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": _vm.form.isActived ? "启用" : "停用",
                    },
                    model: {
                      value: _vm.form.isActived,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isActived", $$v)
                      },
                      expression: "form.isActived",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "footers flex_c_c",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确定修改")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }